import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import { useUser } from '../../../App';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { saveAs } from "file-saver";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ExcelJS from "exceljs";
import Footer from "../Footer/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "font-awesome/css/font-awesome.min.css";
import ErrorComponent from "../404/ErrorComponent";
import io from 'socket.io-client';
import InactiveGuards from "../Guards/InactiveGuards"

ChartJS.register(ArcElement, Tooltip, Legend);

const getPresentDay = () => {
  const presentTime = new Date();
  const dd = String(presentTime.getDate()).padStart(2, "0");
  const mm = String(presentTime.getMonth() + 1).padStart(2, "0");
  const yyyy = presentTime.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};

function Home() {
  const itemsPerPage = 100;

  const location = useLocation();
  const user = useUser();

  const { state } = location;
  const aoName = state?.aoName || "";
  const aoMobile = user?.email === "operations@radiantguards.com" ? state?.aoMobile || "" : "";

  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [graphData, setGraphData] = useState({
    present: 0,
    absent: 0,
  });
  const [date, setDate] = useState(getPresentDay());
  const initialAoNameFilter = user?.email === "operations@radiantguards.com" ? aoName || "" : "";
  const [aoNameFilter, setAONameFilter] = useState(initialAoNameFilter);
  const [isToggled, setIsToggled] = useState(false);
  const [showInactiveGuardsTab, setShowInactiveGuardsTab] = useState(false);

  // console.log("attendanceRecords in home tab", attendanceRecords);
  const getAttendanceRecords = useCallback(async (selectedDate) => {
    try {

      const localToken = localStorage.getItem("auth_token");

      let ddmmyyyy = selectedDate.split("-");
      ddmmyyyy = `${ddmmyyyy[2]}/${ddmmyyyy[1]}/${ddmmyyyy[0]}`;

      const config = {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      };
      setIsLoading(true);
      const { data } = await axios.get(
        `https://guards-apis.onrender.com/api/attendance?date=${ddmmyyyy}`,
        config
      );
      setIsLoading(false);
      let filteredRecords = data.filter(
        (record) =>
          record.guard.aoName.toLowerCase().includes(aoNameFilter.toLowerCase())
      );

      // If filteredRecords is empty and isToggled is true, mark all guards as present
      if (filteredRecords.length === 0 && isToggled) {
        filteredRecords = data.map(record => ({ ...record, checkIn: true }));
      }

      const checkInTrueRecords = filteredRecords.filter(
        (record) => record.checkIn === true
      );
      const noPresent = checkInTrueRecords.length;

      const checkInFalseRecords = filteredRecords.filter(
        (record) => record.checkIn === false
      );
      const noAbsent = checkInFalseRecords.length;

      const checkDistIsNull = filteredRecords.filter(
        (record) => record.lastCheckInDistance === null
      );
      const checkDistIsPresent = filteredRecords.filter(
        (record) => record.lastCheckInDistance !== null
      );
      setGraphData({
        present: user?.email == 'glcontrolroomthane@iifl.com' ? checkDistIsPresent.length : noPresent,
        absent: user?.email == 'glcontrolroomthane@iifl.com' ? checkDistIsNull.length : noAbsent,
      });


      // Set the attendance records

      // Set the attendance records
      setAttendanceRecords(filteredRecords);

    } catch (err) {
      console.error("error in .me: ", err, err.message);
      setIsLoading(false);
    }
  }, [isToggled]);

  const handleInactiveGuards = () => {
    setShowInactiveGuardsTab(!showInactiveGuardsTab);
  }

  useEffect(() => {
    getAttendanceRecords(date);
  }, [date]);

  useEffect(() => {

    const socket = io('https://whatsapp-wbhook.onrender.com');

    socket.on('presenty', (message) => {
      // console.log('Received message from server:', message);
      setTimeout(() => {
        getAttendanceRecords(date);
      }, 0)
    });

    // Cleanup when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  const isAoMobilePresent = () => {
    return attendanceRecords.some(record => record.guard.aoWhatsapp === aoMobile);
  };

  const handleFilter = async () => {
    setIsFiltering(!isFiltering);

    // Filter the data based on AO Name only if the filter is not empty
    const filteredData = aoNameFilter
      ? attendanceRecords.filter((record) =>
        record.guard.aoName.toLowerCase().includes(aoNameFilter.toLowerCase())
      )
      : attendanceRecords;

    // Update the filtered data state
    setFilteredData(filteredData);

    // Calculate present/absent counts for the filtered data
    const checkInTrueRecords = filteredData.filter((record) => record.checkIn === true);
    const noPresent = checkInTrueRecords.length;

    const checkInFalseRecords = filteredData.filter((record) => record.checkIn === false);
    const noAbsent = checkInFalseRecords.length;

    const checkDistIsNull = filteredData.filter(
      (record) => record.lastCheckInDistance === null
    );
    const checkDistIsPresent = filteredData.filter(
      (record) => record.lastCheckInDistance !== null
    );
    // Update the graph data state
    setGraphData({
      present: user?.email == 'glcontrolroomthane@iifl.com' ? checkDistIsPresent.length : noPresent,
      absent: user?.email == 'glcontrolroomthane@iifl.com' ? checkDistIsNull.length : noAbsent,
    });

    setCurrentPage(1);
  };

  const handleRefresh = () => {
    getAttendanceRecords(date);
  };

  const filteredRecords = isFiltering ? filteredData : attendanceRecords;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalItems = filteredRecords.length;

  const filteredTableData = filteredRecords
    .filter((record) =>
      record.guard.aoName.toLowerCase().includes(aoNameFilter.toLowerCase())
    )
    .sort((a, b) => {
      if (user?.email === 'glcontrolroomthane@iifl.com') {
        if (a.lastCheckInDistance !== null && b.lastCheckInDistance === null) {
          return 1;
        } else if (a.lastCheckInDistance === null && b.lastCheckInDistance !== null) {
          return -1;
        }
      }

      // Custom sorting logic for checkIn and checkOut status
      const aStatus = (a.checkIn ? 1 : 0) + (a.checkOut ? 2 : 0);
      const bStatus = (b.checkIn ? 1 : 0) + (b.checkOut ? 2 : 0);

      return aStatus - bStatus;
    })
    .slice(startIndex, endIndex);


  const downloadXLSXData = async () => {
    let filename = aoNameFilter
      ? `${aoNameFilter} Guard Data.xlsx`
      : 'Home Page Guard Data.xlsx';

    const filteredRecords = isFiltering
      ? attendanceRecords.filter(
        (record) =>
          record.guard.aoName.toLowerCase().includes(aoNameFilter.toLowerCase())
      )
      : attendanceRecords;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("GuardData");
    worksheet.columns = [
      { header: "Sr No.", key: "SrNo" },
      { header: "Date", key: "Date" },
      { header: "Zone", key: "Zone" },
      { header: "Branch Code", key: "BranchCode" },
      { header: "Branch Name", key: "BranchName" },
      { header: "State", key: "State" },
      { header: user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Name of Seller" : "Name of Guard", key: "NameOfGuard" },
      { header: user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Seller's Mobile Number" : "Guard's Mobile Number", key: "MobileNumber" },
      { header: user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Seller's Age" : "Guard's Age", key: "Age" },
      { header: user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Shopkeeper Name" : "Ao Name", key: "AoName" },
      { header: user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Shopkeeper Number" : "Ao Number", key: "AoNumber" },
      { header: "Vendor Name", key: "vendor" },
      { header: "Punch In Time", key: "ReportingTimeForPunchIn" },
      { header: "Punch out Time", key: "ReportingTimeForPunchOut" },
      { header: user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Attendance of Seller" : "Attendance of Guards", key: "PresentAbsent" },
    ];

    // Add data to the worksheet (filteredData is an array of objects)
    worksheet.addRows(
      filteredRecords.map((record) => ({
        SrNo: record.guard.srNo,
        Date: record.date,
        Zone: record.guard.zone,
        BranchCode: record.guard.branchCode,
        BranchName: record.guard.branchName,
        State: record.guard.state,
        NameOfGuard: record.guard.nameOfGuard,
        MobileNumber: record.guard.guardWhatsapp,
        Age: record.guard.guardAge,
        AoName: record.guard.aoName,
        AoNumber: record.guard.aoWhatsapp,
        vendor: record.guard.vendorName,
        ReportingTimeForPunchIn: record.lastCheckInTime ? new Date(record.lastCheckInTime).toLocaleTimeString('en-IN', { hour12: true, hour: '2-digit', minute: '2-digit' }) : '',
        ReportingTimeForPunchOut: record.lastCheckOutTime ? new Date(record.lastCheckOutTime).toLocaleTimeString('en-IN', { hour12: true, hour: '2-digit', minute: '2-digit' }) : '',
        // ReportingDistance: record.lastCheckInDistance !== null ? (
        //   record.lastCheckInDistance === 0 ? (
        //     "0 Meter"
        //   ) : (
        //     ${record.lastCheckInDistance} Meters
        //   )
        // ) : (
        //   ""
        // ),

        PresentAbsent: user?.email === 'glcontrolroomthane@iifl.com'
          ? (record.lastCheckInDistance !== null ? "Present" : "Absent")
          : (record.checkIn ? "Present" : "Absent")
      }))
    );

    // Generate the XLSX file as a Blob
    const blob = await workbook.xlsx.writeBuffer();

    // Create a Blob object and initiate the download
    const xlsxBlob = new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(xlsxBlob, filename);
  };

  const data = {
    labels: ["Absent", "Present"],
    datasets: [
      {
        data: [graphData.absent, graphData.present],
        backgroundColor: ["red", "green"],
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          generateLabels: function (chart) {
            const data = chart.data;
            const datasets = data.datasets;
            if (datasets.length) {
              const dataset = datasets[0];
              const absentValue = dataset.data[0];
              const presentValue = dataset.data[1];

              return [
                {
                  text: `Absent: ${absentValue}`,
                  fillStyle: "red",
                  hidden: false,
                  index: 0,
                  datasetIndex: 0,
                },
                {
                  text: `Present: ${presentValue}`,
                  fillStyle: "green",
                  hidden: false,
                  index: 1,
                  datasetIndex: 0,
                },
              ];
            }
            return [];
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  };
  const handleSwitchChange = async (guardData) => {
    // console.log("guard data from handleSwitchChange", guardData);
    try {
      const localToken = localStorage.getItem("auth_token");

      const config = {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      };

      const body = { date: guardData.date };
      setIsLoading(true);

      const { data } = await axios.patch(
        `https://guards-apis.onrender.com/api/attendance/${guardData.id}`,
        body,
        config,
      );
      setIsLoading(false);
      window.location.reload();
      // console.log("data from handleSwitchChange", data);
    }
    catch (err) {
      console.error("error in handleSwitchChange: ", err.response.data.message);
      alert(`The guard with mobile number : ${guardData.guard.guardWhatsapp} is not punched in.`)
      setIsLoading(false)
    }
  }
  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size="9rem" sx={{ color: "#154d32" }} />
      </Box>
    );

  if (user?.email === "operations@radiantguards.com" && !isAoMobilePresent()) {
    // Show "Go back" button if aoMobile is not present
    return (
      <div>
        <ErrorComponent />
      </div>
    );
  }

  return (
    <div>
      {
        showInactiveGuardsTab ? (<InactiveGuards graphData={graphData} attendanceRecordsFromHome={attendanceRecords} showInactiveGuardsTab={showInactiveGuardsTab} setShowInactiveGuardsTab={setShowInactiveGuardsTab} />) : (
          <div className="border border-light rounded ml-3 mr-4 shadow-md">
            <div
              className="d-flex flex-column flex-lg-row ml-3"
              style={{ marginTop: "20px" }}
            >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} size="small">
                  <TableHead>
                    <TableRow style={{ whiteSpace: 'nowrap', backgroundColor: user?.email == 'glcontrolroomthane@iifl.com' && '#154d32' }}>
                      {/* Update Table Headers based on the organization id */}
                      {user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? (
                        <>
                          <TableCell>Name of Seller</TableCell>
                          <TableCell>Branch Code</TableCell>
                          <TableCell>Mobile Number</TableCell>
                          <TableCell>Reporting Time</TableCell>
                          <TableCell>Reporting LatLong</TableCell>
                          <TableCell>Reporting Distance</TableCell>
                          <TableCell>Shopkeeper Name</TableCell>
                          <TableCell>Shopkeeper Number</TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell style={{ color: user?.email == 'glcontrolroomthane@iifl.com' && 'white' }}>Name of Guard</TableCell>
                          <TableCell style={{ color: user?.email == 'glcontrolroomthane@iifl.com' && 'white' }}>Branch Code</TableCell>
                          <TableCell style={{ color: user?.email == 'glcontrolroomthane@iifl.com' && 'white' }}>Mobile Number</TableCell>
                          <TableCell style={{ color: user?.email == 'glcontrolroomthane@iifl.com' && 'white' }}>Reporting Time</TableCell>
                          {user?.email !== 'glcontrolroomthane@iifl.com' && <TableCell>Reporting LatLong</TableCell>}
                          {user?.email !== 'glcontrolroomthane@iifl.com' && <TableCell>Reporting Distance</TableCell>}
                          <TableCell style={{ color: user?.email == 'glcontrolroomthane@iifl.com' && 'white' }}>Ao Name</TableCell>
                          <TableCell style={{ color: user?.email == 'glcontrolroomthane@iifl.com' && 'white' }}>Ao Number</TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody className="custom-table">
                    {filteredTableData.map((row, rowIndex) => {
                      return (
                        <TableRow
                          key={rowIndex}
                          style={{ backgroundColor: user?.email === 'glcontrolroomthane@iifl.com' ? 'white' : ((row.checkIn && row.checkOut === false) ? 'green' : 'red') }}
                        >
                          <TableCell style={{ whiteSpace: 'nowrap' }}>{row.guard.nameOfGuard}</TableCell>
                          <TableCell>{row.guard.branchCode}</TableCell>
                          <TableCell>{row.guard.guardWhatsapp}</TableCell>
                          <TableCell>
                            {row.lastCheckOutTime ? new Date(row.lastCheckOutTime).toLocaleTimeString('en-IN', { hour12: true, hour: '2-digit', minute: '2-digit' }) : row.lastCheckInTime ? new Date(row.lastCheckInTime).toLocaleTimeString('en-IN', { hour12: true, hour: '2-digit', minute: '2-digit' }) : ''}
                          </TableCell>
                          {user?.email !== 'glcontrolroomthane@iifl.com' && <TableCell>
                            {row.lastCheckOutLatLong ? row.lastCheckOutLatLong.split(':').join(',') :
                              row.lastCheckInLatLong ? row.lastCheckInLatLong.split(':').join(',') : ''}
                          </TableCell>}
                          {user?.email !== 'glcontrolroomthane@iifl.com' && <TableCell style={{ whiteSpace: 'nowrap' }}>
                            {row.lastCheckOutDistance !== null ? (
                              row.lastCheckOutDistance === 0 ? (
                                "0 Meter"
                              ) : (
                                row.lastCheckOutDistance === 1 ? (
                                  "1 Meter"
                                ) : (
                                 ` ${row.lastCheckOutDistance} Meters`
                                )
                              )
                            ) : (
                              row.lastCheckInDistance !== null ? (
                                row.lastCheckInDistance === 0 ? (
                                  "0 Meter"
                                ) : (
                                  row.lastCheckInDistance === 1 ? (
                                    "1 Meter"
                                  ) : (
                                   ` ${row.lastCheckInDistance} Meters`
                                  )
                                )
                              ) : (
                                ""
                              )
                            )}</TableCell>}
                          <TableCell style={{ whiteSpace: 'nowrap' }}>{row.guard.aoName}</TableCell>
                          <TableCell>{row.guard.aoWhatsapp}</TableCell>
                          {(user?.email !== "operations@radiantguards.com" && user?.email !== "glcontrolroomthane@iifl.com") && <TableCell>
                            {!row.checkIn && <div className="form-check form-switch" onClick={() => { handleSwitchChange(row) }}>
                              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>}
                          </TableCell>}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className="chart-container shadow-md">
                {(user?.email !== "operations@radiantguards.com" && user?.organization?.id !== "clvafmvaz09mnmr1tifr2prjf") && (
                  <div style={{ position: 'relative', width: '100%' }}>
                    <input
                      type="input"
                      placeholder="Search by AO Name"
                      style={{ width: "100%", marginBottom: "25px", paddingRight: "30px" }}
                      value={aoNameFilter}
                      onChange={(e) => setAONameFilter(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{
                        position: "absolute",
                        right: "0.4px",
                        top: "26.8%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        backgroundColor: "#154d32",
                        color: "#fff",
                        padding: "6.8px 10px",
                        borderTopRightRadius: "3px",
                        borderBottomRightRadius: "1px",
                      }}
                      onClick={handleFilter}
                    />
                  </div>)}
                <input
                  type="date"
                  value={date}
                  style={{ width: "100%", marginBottom: "5px" }}
                  onChange={(e) => setDate(e.target.value)}
                />
                <div
                  style={{
                    border: "1px solid rgb(240, 240, 240)",
                    borderRadius: "5px",
                  }}
                >
                  <h3 className="pie-heading">Analytics</h3>
                  <Pie
                    data={data}
                    options={options}
                    height={0}
                    width={0}
                    className="chart-container-pie"
                  />
                </div>
                {(user?.organization?.id !== "clvafmvaz09mnmr1tifr2prjf" && user?.email !== "operations@radiantguards.com" && user?.email !== "glcontrolroomthane@iifl.com") && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "3%" }}>
                  <button type="button" className="btn btn-secondary" onClick={handleInactiveGuards}>Inactive Guards</button>
                </div>}
              </div>
            </div>
            <div>
              <Footer
                downloadXLSXData={downloadXLSXData}
                handleFilter={handleFilter}
                handleRefresh={handleRefresh}
                isFiltering={isFiltering}
                totalItems={totalItems}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        )
      }
    </div>
  );
}

export default Home;